.widgetCard {
  width: 45%;
  height: 140px;
  background: #1C1C1E;
  border-radius: 30px;
  padding: 20px;
  margin: 10px;
}
.widgetCard .title {
  color: #1d76ee;
}
.widgetCard .stats {
  color: #fff;
  font-size: 35px;
  margin-top: 15px;
  font-family: "Apple medium", serif;
}
.widgetCard .difference {
  max-height: 5px;
  margin-top: 8px;
  font-size: 14px;
  color: #1cb700;
}
.widgetCard .difference .anticon-arrow-up svg {
  fill: #1cb700;
}
.widgetCard .difference_null {
  margin-top: 8px;
  color: #646464;
}
.widgetCard .difference_null .anticon-arrow-up svg {
  fill: #646464;
}

