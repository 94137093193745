@import "../../../styles/colors";

.revenue_98283287 {
  width: 93%;
  min-height: 20px;
  background: $widgetBg;
  margin: 0px auto;
  border-radius: 25px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  .anticon-arrow-right {
    margin-left: auto;
    margin-right: 10px;
  }
  .revenue_header {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 17px;
    .revenue_header_h1 {
      margin-left: 20px;
      color: $blue;
    }
  }
  .revenue_header_h2 {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 5px;
    color: $blue;
  }
  .revenue_field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    &:first-child {
      margin-top: 0px;
    }
    .revenue_field_h1 {
      width: 60%;
      line-height: 1.2;
    }
    .revenue_field_data {
      font-size: 17px;
    }
  }
}