@import "default.css";

$yellow: #EDD400;
$widgetBg: #1C1C1E;
$bg: #000;
$blue: #3F8EF5;

@font-face {
  font-family: "Apple medium";
  src: url('./fonts/SFProDisplay-Medium.woff'), url('./fonts/SFProDisplay-Medium.woff2');
}

@font-face {
  font-family: "Apple regular";
  src: url('./fonts/SFProDisplay-Regular.woff'), url('./fonts/SFProDisplay-Regular.woff2');
}

@font-face {
  font-family: "Apple bold";
  src: url('./fonts/SFProDisplay-Semibold.woff'), url('./fonts/SFProDisplay-Semibold.woff2');
}

body, html {
  width: 100%;
  height: 100%;
  background: $bg;
  color: #fff;
  font-family: "Apple regular";
  overflow-x: hidden;
}