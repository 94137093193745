@import "../../../styles/colors";

.ads_component {
  width: 93%;
  min-height: 100px;
  border-radius: 15px;
  background: $widgetBg;
  color: #fff;
  padding: 15px;
  padding-bottom: 10px;
  margin: 0px auto;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  .ads_channel {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Apple medium", serif;
    margin-bottom: 10px;
    font-size: 16px;
    .ads_adsStatus {
      background: $blackBlue;
      color: $blue;
      padding: 5px;
      padding-left: 8px;
      padding-right: 8px;
      margin-left: 10px;
      border-radius: 10px;
    }
  }
  .ads_component_stats {
    width: 100%;
    margin-top: 10px;
  }
  // Antd UI
  .ant-progress {
    width: 93%;
    margin-right: auto;
    margin-top: 5px;
    .ant-progress-bg {
      background: $blue;
    }
    .ant-progress-text {
      color: $blue;
    }
  }
}