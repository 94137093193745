.dailyStats {
  width: 95%;
  background: #1C1C1E;
  border-radius: 30px;
  margin: 0px auto;
  margin-top: 15px;
  padding: 20px;
}
.dailyStats .dailyStats_header {
  width: 100%;
  color: #1d76ee;
  margin-bottom: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.dailyStats .dailyStats_header .infoicon {
  margin-right: 15px;
}
.dailyStats .ant-empty {
  margin-bottom: 20px;
}
.dailyStats .ant-empty .ant-empty-description {
  color: white;
  font-family: "Apple bold", serif;
}

.dailyActiveUsersToday {
  width: 95%;
  min-height: 50px;
  background: #1C1C1E;
  border-radius: 30px;
  margin: 0px auto;
  margin-top: 15px;
  padding: 20px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dailyActiveUsersToday .dailyActiveUsersToday_header {
  width: 55%;
  color: #1d76ee;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.dailyActiveUsersToday .dailyActiveUsersToday_header .infoicon {
  margin-right: 15px;
  margin-bottom: 25px;
}
.dailyActiveUsersToday .dailyActiveUsersCount {
  width: 45%;
  font-size: 32px;
  margin-bottom: 5px;
  padding-left: 30px;
}
.dailyActiveUsersToday .dailyActiveUsersCount .dailyActiveUsersCount_difference {
  max-height: 5px;
  margin-top: 5px;
  font-size: 14px;
  color: #1cb700;
  white-space: nowrap;
}
.dailyActiveUsersToday .dailyActiveUsersCount .dailyActiveUsersCount_difference .anticon-arrow-up svg {
  fill: #1cb700;
}
.dailyActiveUsersToday .dailyActiveUsersCount .dailyActiveUsersCount_null {
  margin-top: 5px;
  color: #646464;
  white-space: nowrap;
  font-size: 14px;
}
.dailyActiveUsersToday .dailyActiveUsersCount .dailyActiveUsersCount_null .anticon-arrow-up svg {
  fill: #646464;
}

