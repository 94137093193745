.ads_component {
  width: 93%;
  min-height: 100px;
  border-radius: 15px;
  background: #1C1C1E;
  color: #fff;
  padding: 15px;
  padding-bottom: 10px;
  margin: 0px auto;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
.ads_component .ads_channel {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Apple medium", serif;
  margin-bottom: 10px;
  font-size: 16px;
}
.ads_component .ads_channel .ads_adsStatus {
  background: rgba(16, 74, 141, 0.18);
  color: #1d76ee;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 10px;
  border-radius: 10px;
}
.ads_component .ads_component_stats {
  width: 100%;
  margin-top: 10px;
}
.ads_component .ant-progress {
  width: 93%;
  margin-right: auto;
  margin-top: 5px;
}
.ads_component .ant-progress .ant-progress-bg {
  background: #1d76ee;
}
.ads_component .ant-progress .ant-progress-text {
  color: #1d76ee;
}

