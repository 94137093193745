$yellow: #EDD400;
$green: #1cb700;
$blackGreen: #659335;
$purple: #9073F7;
$blackPurple: #59479a;
$widgetBg: #1C1C1E;
$bg: #000;
$modalbg: #2d2d2d;
$blue: #1d76ee;
$blackBlue: rgba(16, 74, 141, 0.18);
$red: #ff0f0f;
$transition: all, 0.2s, all, 0.2s;
