.uploadApiKeysWidget {
  width: 95%;
  min-height: 50px;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  background: #1C1C1E;
  border-radius: 30px;
}
.uploadApiKeysWidget .uploadApiKeysWidget_header {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 17px;
  padding-left: 5px;
}
.uploadApiKeysWidget .uploadApiKeysWidget_header span {
  display: inline-block;
  margin-left: 10px;
}
.uploadApiKeysWidget .uploadApiKeysWidget_header .header_closeKeyboardButton {
  width: 70px;
  height: 30px;
  padding-top: 7px;
  font-size: 14px;
  background: #1d76ee;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  margin-left: auto;
  margin-right: 7px;
  transition: all, 0.2s, all, 0.2s;
}
.uploadApiKeysWidget .uploadApiKeysWidget_header .header_closeKeyboardButton:active {
  transform: scale(0.95);
}
.uploadApiKeysWidget .uploadApiKeysForm {
  width: 100%;
  margin-top: 10px;
}
.uploadApiKeysWidget .uploadApiKeysForm .uploadApiKeysInput {
  width: 100%;
  height: 40px;
  background: #2d2d2d;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  border-color: #2d2d2d;
}
.uploadApiKeysWidget .uploadApiKeysForm .uploadApiKeysInput:focus {
  border-color: #1d76ee;
}
.uploadApiKeysWidget .uploadApiKeysForm .uploadApiKeysInput::placeholder {
  color: #fff;
}
.uploadApiKeysWidget .uploadApiKeysForm .uploadApiKeysButton {
  border-radius: 20px;
  background: #1d76ee;
  width: 80px;
  height: 37px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all, 0.2s, all, 0.2s;
  margin-top: 17px;
}
.uploadApiKeysWidget .uploadApiKeysForm .uploadApiKeysButton:active {
  transform: scale(0.9);
}

