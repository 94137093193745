@import "../../../styles/colors";

.ads_page {
  width: 100%;
  height: 100%;
  .ads_header {
    width: 100%;
    min-height: 60px;
    background: $widgetBg;
    border-bottom: #2d2d2d 1px solid;
    padding: 15px;
    color: $blue;
    font-size: 16px;
    .ads_header_backbutton {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 17px;
      .span {
        margin-left: 8px;
      }
      .span2 {
        margin-left: auto;
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .ads_header_title {
      width: 100%;
      font-size: 27px;
      color: white;
      font-family: "Apple bold", serif;
      padding-left: 5px;
      margin-top: 10px;
    }
  }
}