.ads_page {
  width: 100%;
  height: 100%;
}
.ads_page .ads_header {
  width: 100%;
  min-height: 60px;
  background: #1C1C1E;
  border-bottom: #2d2d2d 1px solid;
  padding: 15px;
  color: #1d76ee;
  font-size: 16px;
}
.ads_page .ads_header .ads_header_backbutton {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 17px;
}
.ads_page .ads_header .ads_header_backbutton .span {
  margin-left: 8px;
}
.ads_page .ads_header .ads_header_backbutton .span2 {
  margin-left: auto;
  font-size: 28px;
  margin-right: 10px;
}
.ads_page .ads_header .ads_header_title {
  width: 100%;
  font-size: 27px;
  color: white;
  font-family: "Apple bold", serif;
  padding-left: 5px;
  margin-top: 10px;
}

