@import "../../../styles/colors";


.createProduserWidget {
  width: 95%;
  margin: 0px auto;
  height: 45px;
  background: $widgetBg;
  border-radius: 25px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .createProduserWidget_h1 {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: $blue;
    display: flex;
    align-items: center;
    .usersicon {
      margin-right: 15px;
    }
  }
}