@import "../../../../../styles/colors";

.userStatsModal {
  // header
  .userStatsModal_header {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $blue;
    margin-bottom: 15px;
    .header_p {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }
  // body
  //// validation span
  .validation_span {
    width: 100%;
    height: 20px;
    padding-left: 15px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .doneicon {
      margin-top: 3px;
      margin-left: 5px;
    }
  }
  //// input
  .userStatsModal_input {
    width: 100%;
    height: 45px;
    background: $modalbg;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    padding-left: 15px;
    border-color: $widgetBg;
    margin-bottom: 10px;
    font-size: 17px;
    &:focus {
      border-color: #606060;
    }
    &::placeholder {
      color: #adadad;
      font-size: 14px;
    }
  }
  // Antd UI
  .ant-modal-content {
    background: $widgetBg;
    color: #fff;
    font-family: "Apple regular", serif;
    //transform: translate(0px, -20px);
    .ant-modal-footer {
      button {
        border: none;
        box-shadow: none;
        color: white;
      }
      button:nth-child(1) {
        background: none;
      }
      button:nth-child(2) {
        background: $blue;
      }
    }
  }
}