@import "../../../styles/colors";

.widgetCard {
  width: 45%;
  height: 145px;
  background: $widgetBg;
  border-radius: 30px;
  padding: 20px;
  margin: 10px;
  .title {
    color: $blue;
  }
  .stats {
    color: #fff;
    font-size: 45px;
    margin-top: 12px;
    font-family: "Apple medium";
  }
}