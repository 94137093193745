.createProduserWidget {
  width: 95%;
  margin: 0px auto;
  height: 45px;
  background: #1C1C1E;
  border-radius: 25px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.createProduserWidget .createProduserWidget_h1 {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #1d76ee;
  display: flex;
  align-items: center;
}
.createProduserWidget .createProduserWidget_h1 .usersicon {
  margin-right: 15px;
}

