.statistics_page {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.statistics_page .statistics_page_title {
  width: 93%;
  margin: 0px auto;
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 5px;
  color: #1d76ee;
  background: #1C1C1E;
  border-radius: 25px;
  height: 50px;
  padding: 20px;
  padding-top: 15px;
}
.statistics_page .statistics_page_title .anticon-search {
  margin-left: 10px;
}
.statistics_page .statistics_page_title .anticon-search svg {
  fill: white;
}
.statistics_page .statistics_page_header {
  width: 100%;
  height: 85px;
  font-size: 25px;
  margin-bottom: 20px;
  background: #1C1C1E;
  padding: 20px;
  padding-top: 10px;
  font-family: "Apple bold", serif;
}
.statistics_page .statistics_page_header .statistics_page_header_backbutton {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.statistics_page .statistics_page_header .statistics_page_header_backbutton .span-1231 {
  color: #1d76ee;
  font-size: 16px;
  transform: translate(0, -1px);
  margin-left: 5px;
  font-family: "Apple medium", serif;
}
.statistics_page .statistics_page_header h1 {
  width: 100%;
  display: flex;
}
.statistics_page .statistics_page_header h1 .statsicon {
  margin-left: 10px;
}
.statistics_page .statistics_page_inactive_to_active {
  width: 93%;
  min-height: 100px;
  margin: 0px auto;
  background: #1C1C1E;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 20px;
}
.statistics_page .statistics_page_inactive_to_active .statistics_page_inactive_to_active_h1 {
  width: 100%;
  font-size: 18px;
}
.statistics_page .statistics_page_inactive_to_active [data-chart-source-type=G2Plot] {
  width: 100%;
  height: 230px;
  margin-top: 20px;
  transform: scale(1.1);
}
.statistics_page .revenue {
  width: 93%;
  min-height: 140px;
  background: #1C1C1E;
  margin: 0px auto;
  border-radius: 25px;
  margin-bottom: 15px;
  padding: 25px;
}
.statistics_page .revenue .revenue_field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.statistics_page .revenue .revenue_field:first-child {
  margin-top: 0px;
}
.statistics_page .revenue .revenue_field .revenue_field_h1 {
  width: 60%;
  line-height: 1.2;
  font-size: 16px;
}
.statistics_page .revenue .revenue_field .revenue_field_data {
  font-size: 18px;
  font-family: "Apple bold", serif;
}
.statistics_page .statistics_page_totalPeriodStats {
  width: 90%;
  min-height: 100px;
  border-radius: 25px;
  margin: 0px auto;
  background: #1C1C1E;
  padding: 20px;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.statistics_page .statistics_page_totalPeriodStats .statistics_page_totalPeriodStats_field {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 10px;
}
.statistics_page .statistics_page_totalPeriodStats .statistics_page_totalPeriodStats_field .statistics_page_totalPeriodStats_field_data {
  font-size: 18px;
  font-family: "Apple bold", serif;
}
.statistics_page .statistics_page_collapse {
  width: 90%;
  margin: 0px auto;
}
.statistics_page .statistics_page_collapse .ant-collapse-expand-icon {
  transform: translate(0, 2px);
}
.statistics_page .statistics_page_collapse .ant-collapse-header-text {
  color: white;
  font-size: 17px;
  font-family: "Apple bold", serif;
}
.statistics_page .statistics_page_collapse svg {
  fill: white;
}
.statistics_page .statistics_page_collapse .ant-collapse-content-box {
  color: white;
}
.statistics_page .statistics_page_collapse .ant-collapse-content-box .collapse_panel_p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.statistics_page .statistics_page_collapse .ant-collapse-content-box .collapse_panel_p div {
  font-family: "Apple bold", serif;
  font-size: 17px;
}
.statistics_page .statistics_page_rangePicker {
  margin-bottom: 20px;
}

.ant-picker {
  border-radius: 20px;
  width: 90%;
  height: 45px;
}

.ant-picker-panel-container {
  border-radius: 35px;
  width: 80%;
  margin-top: 280px;
  transform: translate(12%, 0%);
  margin-left: 0;
}
.ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels {
  display: flex;
  flex-wrap: wrap;
}

