@import "../../../styles/colors";

.dailyStats {
  width: 95%;
  background: $widgetBg;
  border-radius: 30px;
  margin: 0px auto;
  margin-top: 15px;
  padding: 20px;
  .dailyStats_header {
    width: 100%;
    color: $blue;
    margin-bottom: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    .infoicon {
      margin-right: 15px;
    }
  }
  .ant-empty {
    margin-bottom: 20px;
    .ant-empty-description {
      color: white;
      font-family: "Apple bold", serif;
    }
  }
}
.dailyActiveUsersToday {
  width: 95%;
  min-height: 50px;
  background: $widgetBg;
  border-radius: 30px;
  margin: 0px auto;
  margin-top: 15px;
  padding: 20px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dailyActiveUsersToday_header {
    width: 55%;
    color: $blue;
    font-size: 16px;
    display: flex;
    align-items: center;
    .infoicon {
      margin-right: 15px;
      margin-bottom: 25px;
    }
  }
  .dailyActiveUsersCount {
    width: 45%;
    font-size: 32px;
    margin-bottom: 5px;
    padding-left: 30px;
    .dailyActiveUsersCount_difference {
      max-height: 5px;
      margin-top: 5px;
      font-size: 14px;
      color: $green;
      white-space: nowrap;
      .anticon-arrow-up {
        svg {
          fill: $green;
        }
      }
    }
    .dailyActiveUsersCount_null {
      margin-top: 5px;
      color: #646464;
      white-space: nowrap;
      font-size: 14px;
      .anticon-arrow-up {
        svg {
          fill: #646464;
        }
      }
    }
  }
}


