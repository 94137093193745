.editservicestatus {
  width: 75%;
  min-height: 50px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  background: #1C1C1E;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  margin-right: 5px;
}
.editservicestatus .editservicestatus_header {
  font-size: 16px;
  color: #1d76ee;
}

.alertservice {
  width: 20%;
  background: #1C1C1E;
  border-radius: 20px;
  min-height: 50px;
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

