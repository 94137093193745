@import "../../../styles/colors";

.dashboard {
  width: 100%;
  height: 100%;
  padding: 10px;
  .dashboard_header {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 50px;
    .username {
      font-size: 18px;
      margin-left: 10px;
    }
  }
  .dashboard_content {
    width: 100%;
    .content_widgets {
      width: 100%;
      display: flex;
    }
    .adsWidget {
      width: 95%;
      margin: 0px auto;
      height: 45px;
      background: $widgetBg;
      border-radius: 25px;
      padding: 15px;
      padding-left: 20px;
      padding-right: 30px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .adsWidget_h1 {
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: $blue;
        display: flex;
        align-items: center;
        margin-left: 15px;
      }
    }
    .getUserStats {
      width: 95%;
      margin: 0px auto;
      height: 45px;
      background: $widgetBg;
      border-radius: 25px;
      padding: 15px;
      padding-left: 20px;
      padding-right: 30px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      .getUserStats_h1 {
        font-size: 16px;
        color: $blue;
        margin-left: 15px;
      }
    }
  }
}