@import "../../../styles/colors";

.widgetCard {
  width: 45%;
  height: 140px;
  background: $widgetBg;
  border-radius: 30px;
  padding: 20px;
  margin: 10px;
  .title {
    color: $blue;
  }
  .stats {
    color: #fff;
    font-size: 35px;
    margin-top: 15px;
    font-family: "Apple medium", serif;
  }
  .difference {
    max-height: 5px;
    margin-top: 8px;
    font-size: 14px;
    color: $green;
    .anticon-arrow-up {
      svg {
        fill: $green;
      }
    }
  }
  .difference_null {
    margin-top: 8px;
    color: #646464;
    .anticon-arrow-up {
      svg {
        fill: #646464;
      }
    }
  }
}