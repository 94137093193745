.dashboard {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.dashboard .dashboard_header {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 50px;
}
.dashboard .dashboard_header .username {
  font-size: 18px;
  margin-left: 10px;
}
.dashboard .dashboard_content {
  width: 100%;
}
.dashboard .dashboard_content .content_widgets {
  width: 100%;
  display: flex;
}
.dashboard .dashboard_content .adsWidget {
  width: 95%;
  margin: 0px auto;
  height: 45px;
  background: #1C1C1E;
  border-radius: 25px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .dashboard_content .adsWidget .adsWidget_h1 {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #1d76ee;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.dashboard .dashboard_content .getUserStats {
  width: 95%;
  margin: 0px auto;
  height: 45px;
  background: #1C1C1E;
  border-radius: 25px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.dashboard .dashboard_content .getUserStats .getUserStats_h1 {
  font-size: 16px;
  color: #1d76ee;
  margin-left: 15px;
}

