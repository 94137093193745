@import "../../../styles/colors";

.uploadApiKeysWidget {
  width: 95%;
  min-height: 50px;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  background: $widgetBg;
  border-radius: 30px;
  .uploadApiKeysWidget_header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 17px;
    padding-left: 5px;
    span {
      display: inline-block;
      margin-left: 10px;
    }
    .header_closeKeyboardButton {
      width: 70px;
      height: 30px;
      padding-top: 7px;
      font-size: 14px;
      background: $blue;
      color: #fff;
      text-align: center;
      border-radius: 20px;
      margin-left: auto;
      margin-right: 7px;
      transition: $transition;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .uploadApiKeysForm {
    width: 100%;
    margin-top: 10px;
    .uploadApiKeysInput {
      width: 100%;
      height: 40px;
      background: $modalbg;
      color: #fff;
      border-radius: 20px;
      padding: 5px;
      padding-left: 15px;
      border-color: $modalbg;
      &:focus {
        border-color: $blue;
      }
      &::placeholder {
        color: #fff;
      }
    }
    .uploadApiKeysButton {
      border-radius: 20px;
      background: $blue;
      width: 80px;
      height: 37px;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $transition;
      margin-top: 17px;
      &:active {
        transform: scale(0.9);
      }
    }
  }
}