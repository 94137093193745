.widgetCard {
  width: 45%;
  height: 145px;
  background: #1C1C1E;
  border-radius: 30px;
  padding: 20px;
  margin: 10px;
}
.widgetCard .title {
  color: #1d76ee;
}
.widgetCard .stats {
  color: #fff;
  font-size: 45px;
  margin-top: 12px;
  font-family: "Apple medium";
}

