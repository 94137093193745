.userStatsModal .userStatsModal_header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1d76ee;
  margin-bottom: 15px;
}
.userStatsModal .userStatsModal_header .header_p {
  margin-left: 10px;
  margin-bottom: 5px;
}
.userStatsModal .validation_span {
  width: 100%;
  height: 20px;
  padding-left: 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.userStatsModal .validation_span .doneicon {
  margin-top: 3px;
  margin-left: 5px;
}
.userStatsModal .userStatsModal_input {
  width: 100%;
  height: 45px;
  background: #2d2d2d;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  border-color: #1C1C1E;
  margin-bottom: 10px;
  font-size: 17px;
}
.userStatsModal .userStatsModal_input:focus {
  border-color: #606060;
}
.userStatsModal .userStatsModal_input::placeholder {
  color: #adadad;
  font-size: 14px;
}
.userStatsModal .ant-modal-content {
  background: #1C1C1E;
  color: #fff;
  font-family: "Apple regular", serif;
}
.userStatsModal .ant-modal-content .ant-modal-footer button {
  border: none;
  box-shadow: none;
  color: white;
}
.userStatsModal .ant-modal-content .ant-modal-footer button:nth-child(1) {
  background: none;
}
.userStatsModal .ant-modal-content .ant-modal-footer button:nth-child(2) {
  background: #1d76ee;
}

